import ButtonCustom from '@components/core/ButtonCustom';
import TranslatedText from '@components/core/TranslatedText';
import { TYPE_ACCOUNT_AGENCY, TYPE_ACCOUNT_HOST } from '@constants/profile';
import { TYPE_BRAND } from '@constants/system';
import { AGENCY_LIVESTREAM_CALENDAR_ROUTES } from '@features/agency/livestream-calendar/config/route';
import { DASHBOARD_ROUTES } from '@features/dashboard/config/route';
import { LIVESTREAM_CALENDAR_ROUTES } from '@features/livestream-calendar/config/route';
import Cookies from 'js-cookie';
import Head from 'next/head';
import { useRouter } from 'next/router';

const IconNotFound = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='241' height='201' fill='none' viewBox='0 0 241 201'>
    <path fill='#6857A7' d='M1.706 193.785s30.642 12.627 52.234 1.81l8.124-41.417-60.358 39.607z'></path>
    <path
      fill='#000'
      d='M38.62 176.653s17.004 2.694 17.509 7.661l15.826-.463 44.153-45.037-3.62-9.891H71.071l-32.452 47.73z'
    ></path>
    <path fill='#fff' d='M95.357 105.479l-45.036 86.495 14.436 8.082 45.037-89.148-14.437-5.429z'></path>
    <path
      fill='#000'
      d='M65.01 200.94l-15.531-8.713 45.584-87.506 15.615 5.85L65.01 200.94zm-13.848-9.218l13.3 7.492 44.406-87.969-13.216-4.967-44.49 85.444z'
    ></path>
    <path
      fill='#000'
      d='M102.478 108.714l-2.894.25 1.161 13.419 2.893-.25-1.16-13.419zM80.48 137.308l-.403 2.877 13.339 1.87.403-2.876-13.338-1.871zM93.674 130.144a1.886 1.886 0 001.894-1.894 1.887 1.887 0 00-1.894-1.895 1.886 1.886 0 00-1.894 1.895c0 1.052.884 1.894 1.894 1.894zM76.164 149.926c0-.968-.8-1.768-1.768-1.768s-1.767.8-1.767 1.768.8 1.768 1.767 1.768c.968 0 1.768-.758 1.768-1.768zM62.316 197.278l1.516-1.81-10.733-8.797-1.137 2.147 10.354 8.46zM72.376 184.945l.715-1.431-17.804-1.052-.758 1.431 17.847 1.052z'
    ></path>
    <path
      fill='#000'
      d='M91.57 146.938l-1.474.842-10.102-1.852-.42 2.315 7.323 1.389-20.75 11.995-2.273 4.336 12.795 10.522.674-1.304-12.375-10.144 24.665-14.269 1.936-3.83z'
    ></path>
    <path
      fill='#6857A7'
      d='M1.706 193.784s.884-41.417 19.825-60.357c18.94-18.941 27.906-22.519 45.036-22.519 17.131 0 54.045 4.504 54.929 11.701.883 7.198-7.198 16.205-45.921 12.628 0 0-6.314 28.832-36.914 42.343-30.684 13.469-36.955 16.204-36.955 16.204z'
    ></path>
    <path
      fill='#6857A7'
      d='M113.751 132.795s9.554 6.019 7.745 13.216c-1.81 7.198-6.314 9.892-6.314 9.892s5.388 11.701-6.314 19.824c0 0-.883 8.124-10.817 11.701-9.891 3.62-27.906.884-33.335-2.693-5.388-3.62-3.62-10.818 2.693-12.628 0 0-7.197-13.511 8.124-14.394 0 0-9.008-6.314-1.81-12.628 7.197-6.313 39.649-6.313 39.649-6.313l.379-5.977z'
    ></path>
    <path
      fill='#fff'
      d='M89.675 153.251h-1.263c0-2.399-.715-4.209-2.188-5.345-3.157-2.526-8.713-1.263-8.755-1.263l-.295-1.221c.253-.042 6.23-1.389 9.85 1.474 1.767 1.431 2.651 3.535 2.651 6.355zM85.719 171.308c-.21-2.693-1.221-4.714-2.989-6.018-3.746-2.778-9.68-1.558-9.765-1.516l-.252-1.22c.252-.042 6.566-1.347 10.775 1.767 2.062 1.516 3.24 3.873 3.493 6.903l-1.262.084zM77.09 185.661h-1.263c0-1.936-.589-3.452-1.81-4.546-2.651-2.441-7.365-2.02-7.407-2.02l-.127-1.263c.21 0 5.304-.463 8.376 2.357 1.473 1.347 2.231 3.199 2.231 5.472zM98.725 176.738c-19.236 0-31.358-3.957-31.484-3.999l.379-1.178c.168.042 16.415 5.345 41.206 3.577l.084 1.263a143.74 143.74 0 01-10.185.337zM94.263 159.312c-10.186 0-18.604-.968-18.772-.968l.126-1.263c.252.042 23.486 2.652 39.439-.884l.294 1.221c-6.524 1.473-14.226 1.894-21.087 1.894zM114.761 122.567c-5.808 0-9.639-1.179-11.491-3.536-1.725-2.188-.926-4.587-.884-4.672l1.179.421-.589-.21.589.21c-.042.084-.589 1.852.716 3.494 1.389 1.768 5.219 3.704 15.32 2.778l.127 1.262a52.83 52.83 0 01-4.967.253z'
    ></path>
    <path
      fill='#000'
      d='M120.57 114.528c24.385 0 44.152-19.767 44.152-44.152s-19.767-44.153-44.152-44.153S76.417 45.99 76.417 70.376c0 24.385 19.768 44.152 44.153 44.152z'
    ></path>
    <path
      fill='#fff'
      d='M95.61 83.13l8.544-4.925 13.848 11.406.926-1.136-13.427-11.07 25.507-14.732 20.035 3.704.421-2.357-17.257-3.157 7.324-4.25-1.179-2.063-9.765 5.64-10.102-1.852-.421 2.315L127.43 62l-23.907 13.805L87.15 62.336l-.926 1.095 15.952 13.174-7.745 4.462 1.179 2.062zM94.476 94.799l-.084 1.428 41.095 2.425.084-1.429L94.476 94.8zM154.804 66.976l-2.907 13.151 2.836.627 2.907-13.151-2.836-.627zM97.041 33.041l-1.389 6.23 2.82.631 1.936-8.755a29.761 29.761 0 00-3.367 1.895z'
    ></path>
    <path
      fill='#fff'
      d='M108.661 55.48l-2.907 13.152 2.836.627 2.907-13.151-2.836-.627zM89.893 77.569l-2.893.25 1.161 13.42 2.894-.251-1.162-13.42zM121.393 91.475l-.403 2.877 13.338 1.87.404-2.876-13.339-1.87zM120.991 49.727l-.403 2.876 13.338 1.87.404-2.876-13.339-1.87zM95.292 46.057l-.429 1.32 22.421 7.275.428-1.321-22.42-7.274zM113.498 75.356l-.429 1.32 22.421 7.275.428-1.322-22.42-7.273zM134.207 42.554a1.886 1.886 0 001.894-1.894 1.886 1.886 0 00-1.894-1.894 1.886 1.886 0 00-1.894 1.894c0 1.052.842 1.894 1.894 1.894zM124.484 105.942a1.886 1.886 0 00-1.894 1.894c0 1.053.842 1.895 1.894 1.895a1.887 1.887 0 001.894-1.895 1.886 1.886 0 00-1.894-1.894zM80.626 71.765a2.066 2.066 0 00-2.063-2.063 2.066 2.066 0 00-2.062 2.063c0 1.136.926 2.062 2.062 2.062a2.066 2.066 0 002.063-2.062zM139.426 75.132a2.066 2.066 0 00-2.062-2.063 2.066 2.066 0 00-2.063 2.063c0 1.136.926 2.062 2.063 2.062a2.066 2.066 0 002.062-2.062zM116.655 62.336c0-.968-.8-1.768-1.768-1.768s-1.767.8-1.767 1.768.799 1.768 1.767 1.768c.968 0 1.768-.758 1.768-1.768zM77.385 61.368h23.023V59.01H77.932c-.21.757-.379 1.557-.547 2.357zM124.989 29l-2.82-.631-.379 1.768-4.588-3.788c-.673.042-1.347.126-2.02.21l6.271 5.177-2.146 9.807 2.82.632 1.852-8.376 5.009 4.124.926-1.136-5.556-4.588.631-3.199zM141.236 34.767l2.862-.252-.126-1.558a44.58 44.58 0 00-3.073-1.768l.337 3.578zM148.349 53.834h11.87l1.894 1.557a47.483 47.483 0 00-2.02-4.756l-13.722-11.28-1.515 1.852 13.721 11.28h-10.228v1.347zM82.898 93.315l19.951 16.373 1.516-1.851-24.202-19.91c.757 1.895 1.683 3.663 2.736 5.388zM155.252 97.692l-2.778-.926 1.642-2.483-3.452-2.273-2.273 3.41-9.344-3.031-.421 1.304 8.966 2.905-4.378 6.608 3.452 2.273 5.008-7.577 2.652.842c.295-.294.631-.673.926-1.052z'
    ></path>
    <path
      fill='#fff'
      d='M120.57 101.901c17.411 0 31.525-14.114 31.525-31.526 0-17.41-14.114-31.525-31.525-31.525-17.412 0-31.526 14.114-31.526 31.525 0 17.412 14.114 31.526 31.526 31.526z'
    ></path>
    <path
      fill='#000'
      d='M113.429 52.359l.675 7.884 2.013-.172-.675-7.885-2.013.173zM115.141 70.402l3.937 2.816.71-.993-3.937-2.816-.71.993zM139.683 67.803l3.508-3.334-.84-.885-3.509 3.335.841.884zM132.615 58.986l4.668-6.39-1.631-1.192-4.668 6.39 1.631 1.192zM97.075 74.397l-1.12-4.709-1.188.283 1.12 4.709 1.188-.283zM116.956 89.274l-4.835.211.053 1.22 4.836-.212-.054-1.22zM128.146 67.892a1.052 1.052 0 100-2.105 1.052 1.052 0 000 2.105zM153.779 2.988l3.83 8.166 1.01-1.179-2.988-9.05-1.852 2.063zM173.898 25.127a1.558 1.558 0 100-3.116 1.558 1.558 0 000 3.116zM46.574 100.428l-4.461-23.781-40.575 7.576-1.474-7.787 28.538-63.052 12.753-2.399L51.5 65.282l12.206-2.273 1.726 9.26-12.206 2.273 4.461 23.781-11.112 2.105zm-6.187-33.041l-5.472-29.17a259.204 259.204 0 01-2.146-13.763l-.421.084c-1.726 5.599-3.199 9.765-4.84 14.27L11.68 72.48l.043.252 28.663-5.345zM208.328 107.373l5.64-23.529-40.154-9.639 1.852-7.702 51.771-45.921 12.627 3.03-12.879 53.708 12.08 2.904-2.189 9.176-12.08-2.904-5.64 23.528-11.028-2.651zm7.829-32.663l6.945-28.832a254.537 254.537 0 013.662-13.469l-.379-.084c-3.872 4.42-6.903 7.619-10.228 11.028l-28.201 24.286-.084.253 28.285 6.818z'
    ></path>
  </svg>
);

const Page404 = () => {
  const router = useRouter();
  const typeAccountBrand = Number(Cookies.get(TYPE_BRAND));

  const handleClickHome = () => {
    if (typeAccountBrand === TYPE_ACCOUNT_HOST) {
      return router.push(LIVESTREAM_CALENDAR_ROUTES.index);
    }
    if (typeAccountBrand === TYPE_ACCOUNT_AGENCY) {
      return router.push(AGENCY_LIVESTREAM_CALENDAR_ROUTES.index);
    }
    return router.push(DASHBOARD_ROUTES.index);
  };

  return (
    <>
      <Head>
        <title>Page not found</title>
      </Head>
      <div className='h-[100vh] w-100vw flex flex-col justify-center items-center'>
        <p className='mb-0 text-gray-900 font-bold text-[34px] leading-[53px]'>Oops!</p>
        <p className='mb-0 text-gray-500 font-medium text-sm leading-[23px]'>It seems something went wrong</p>
        <div className='py-8'>
          <IconNotFound />
        </div>
        <ButtonCustom onClick={handleClickHome}>
          <TranslatedText translationKey='return_home' className='!text-white' />
        </ButtonCustom>
      </div>
    </>
  );
};

export default Page404;
